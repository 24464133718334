import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout'
import Input, { List, Radios } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';
import useFirmChoices from 'hooks/useFirmChoices';
import useTrustAccountChoices from 'hooks/useTrustAccountChoices';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from "lodash";

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month > 9 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function TrustAccountNav({children}) {
  const [from, setFrom] = useState(startAndEndOfMonth()[0]);
  const [to, setTo] = useState(startAndEndOfMonth()[1]);
  const [exportCSV, setExportCSV] = useState();
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true, false, false, false, false, true);
  const [filters, setFilters] = useState({});
  const {trustAccountChoices, trustAccountId, setTrustAccountId} = useTrustAccountChoices(true, firmId);
  const [activePage, setActivePage] = useState("");
  const [pushedFilters, setPushedFilters] = useState({firmId, from, to, filters, trustAccountId});

  useEffect(() => {
    if (!pushedFilters?.trustAccountId && trustAccountId) {
      setPushedFilters({...pushedFilters, trustAccountId});
    }
  }, [trustAccountId]);

  const updateFilters = () => {
    setPushedFilters({firmId, from, to, filters, trustAccountId});
  }

  return (
    <div class="flex flex-col">
      <div className={`
        border-b md:sticky bg-light-cream dark:bg-dark-coffee dark:border-bone z-30
        md:-top-12
        lg:-top-14
        xl:-top-16
        2xl:-top-16
      `}>
        <PaddedPage noBottom className="flex flex-col gap-2 md:gap-4">
          <h2 className="flex items-center dark:text-bone">
            <div className="inline">🧮</div>&nbsp;
            Trust Account
          </h2>
          <div className="flex gap-4 flex-wrap">
            <Link className={`pb-2 text-sm no-underline ${activePage === '' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account`}>
            Trust Account
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'chequesCleared' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/cheques-cleared`}>
            Cheques Cleared
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'outstandingCheques' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/outstanding-cheques`}>
            Outstanding Cheques
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'taf' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/taf`}>
            TAF Report
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'list' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/list`}>
            Trust Account List
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'liability' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/liability`}>
            Trust Liability
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'reconciliation' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/reconciliation`}>
            Final Reconciliation
            </Link>
            <Link className={`pb-2 text-sm no-underline ${activePage === 'gstpst-report' ? 'border-b dark:border-bone border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/gstpst-report`}>
            GST PST Report
            </Link>
          </div>
        </PaddedPage>
      </div>
      <PaddedPage noBottom>
        <div className="flex justify-between border p-2 bg-light-light-grey dark:bg-shadow border-transparent-dark-grey items-center flex-wrap">
          <div className="flex gap-2 items-center flex-wrap w-full">
            <div className="flex flex-col">
              <label className="dark:text-bone">Firm</label>
              <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={[...firmChoices, {name: "Mine", value: ""}]}/>
            </div>
            {
              (!activePage || ['reconciliation', 'outstandingCheques', 'chequesCleared', 'list'].includes(activePage)) && trustAccountChoices.length >= 1 && 
              <div className="flex flex-col">
                <label className="dark:text-bone">Trust Account</label>
                <List valueOverride={trustAccountId} onChange={setTrustAccountId} className="text-xs py-0.5" options={trustAccountChoices}/>
              </div>
            }
            <div className="flex flex-col">
              <label className="dark:text-bone">Month</label>
              <div className="flex gap-2 items-center">
                {
                  (activePage !== 'outstandingCheques') &&
                  <>
                    <input type="date" className="dark:bg-midnight dark:text-bone dark:[color-scheme:dark]" value={from} onChange={(e) => setFrom(e.target.value)}/>
                    <FontAwesomeIcon icon={faArrowRight}/>
                  </>
                }
                <input type="date" className="dark:bg-midnight dark:text-bone dark:[color-scheme:dark]" value={to} onChange={(e) => setTo(e.target.value)}/>
              </div>
            </div>
            {
              !activePage && 
              <>
                <div className="flex flex-col">
                  <label className="dark:text-bone">Filter</label>
                  <Radios inline options={[{name: "All", value: ""}, {name: "Cleared", value: true}, {name: "Uncleared", value: false}]} valueOverride={filters.cleared} onChange={(cleared) => setFilters({...filters, cleared})} />
                </div>
                <div className="flex flex-col">
                  <label className="dark:text-bone">Type</label>
                  <List
                    className="bg-transparent"
                    onChange={(type) => setFilters({...filters, type})}
                    options={[
                      {name: "All", value: ''},
                      {name: "Bank Draft", value: 'bank draft'},
                      {name: "Cheque", value: 'cheque'},
                      {name: "EFT", value: 'eft'},
                      {name: "Account Transfer", value: 'transfers'},
                      {name: "File Transfer", value: 'file transfer'},
                      {name: "Float", value: 'float'},
                    ]}
                    initialValue={filters.type}
                  />
                </div>
                <div className="flex self-start flex-col">
                  <label className="dark:text-bone">In/Out</label>
                  <hr></hr>
                  <Radios inline options={[{name: "All", value: ""}, {name: "In", value: 'in'}, {name: "Out", value: 'out'}]} valueOverride={filters.inOut} onChange={(inOut) => setFilters({...filters, inOut})} />
                </div>
              </>
            }
            {
              !activePage && 
              <div className="flex flex-col">
                <label className="dark:text-bone">Search by Cheque #</label>
                <Input valueOverride={filters.search} onBlur={(search) => setFilters({...filters, search})}/>
              </div>
            }
            <ButtonPrimary onClick={updateFilters} className="justify-self-end mt-5 text-sm bg-green text-white border-med-grey ml-2 dark:text-shadow">Update</ButtonPrimary>
            {(!activePage || ['taf', 'liability', 'gstpst-report'].includes(activePage)) && exportCSV && <ButtonPrimary onClick={() => exportCSV.call()} className="justify-self-end text-sm border-med-grey mt-5 ml-auto">Export to CSV</ButtonPrimary>}
          </div>
        </div>
      </PaddedPage>
      <PaddedPage class>
        <ActivePageContext.Provider value={{...pushedFilters, setExportCSV, setActivePage, trustAccountChoices}}>
          {children}
        </ActivePageContext.Provider>
      </PaddedPage>
    </div>      
  )
}
