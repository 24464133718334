import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/common/Loading';

// get trust account items for this file
const GET_GSTPST_REPORT = gql`
  query GetgstAndPst($filters: JSONObject, $firmId: String) {
    gstAndPst(filters: $filters, firmId: $firmId) {
      fileNumber,
      nickname,
      completionDate,
      totalFeesAndDisbursements,
      totalGst,
      totalPst,
      surveyId,
      fileId,
    }
  }
`;

export default function GSTPSTReport({filters}) {
  const {setActivePage, from, to, firmId, setExportCSV} = useContext(ActivePageContext);
  setActivePage("gstpst-report")
  const {exportToCsv} = useExportCSV();
  const { loading, error, data, refetch } = useQuery(GET_GSTPST_REPORT, {
    variables: {firmId, filters: {from, to}},
    fetchPolicy:'no-cache'
  });

  const totals = useMemo(() => {
    return data?.gstAndPst?.reduce((prev, current) => {
      prev.gst += Number(current.totalGst);
      prev.pst += Number(current.totalPst);
      prev.feesAndDisbursements += Number(current.totalFeesAndDisbursements);
      return prev;
    }, {gst: 0, pst: 0, feesAndDisbursements: 0});
  }, [data])

  useEffect(() => {
    if (!setExportCSV) return;
    if (data?.gstAndPst?.length > 0) {
      setExportCSV({
        call: () => exportToCsv(data?.gstAndPst?.map((item) => ({fileMatter: item?.fileNumber, completion: item.completionDate, totalFeesAndDisbursements: formatCurrency(item.totalFeesAndDisbursements), gst: formatCurrency(item.totalGst), pst: formatCurrency(item.totalPst) })), `GST/PST Report ${from} - ${to}`)
      })
    } else {
      setExportCSV();
    }
  }, [data]);

  return (
    <div className="bg-light-grey rounded p-2 dark:bg-transparent">
      <table className="w-full bg-light-grey border-spacing-2 dark:bg-transparent">
        <tbody>
          <tr className="border-b border-dark-grey">
            <th>No</th>
            <th>File Matter</th>
            <th>Completion</th>
            <th className="text-right">Total Fees & Disbursements</th>
            <th className="text-right">Total Gst</th>
            <th className="text-right">Total Pst</th>
          </tr>
          {data?.gstAndPst?.map((item, i) => (
            <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
              <td className="p-1 text-center">{i+1}</td>
              <td className="p-1 "><Link to={`/files/${item.surveyId}/${item.fileId}/trust-account`}>{item.fileNumber}/{item.nickname}</Link></td>
              <td className="p-1 text-center">{item.completionDate}</td>
              <td className="p-1 text-right">{formatCurrency(item.totalFeesAndDisbursements)}</td>
              <td className="p-1 text-right">{formatCurrency(item.totalGst)}</td>
              <td className="p-1 text-right">{formatCurrency(item.totalPst)}</td>
            </tr>
          ))}
          <tr className="border-t border-med-grey">
            <td></td>
            <td></td>
            <td></td>
            <td className="text-right pt-2">{formatCurrency(totals?.feesAndDisbursements)}</td>
            <td className="text-right pt-2">{formatCurrency(totals?.gst)}</td>
            <td className="text-right pt-2">{formatCurrency(totals?.pst)}</td>
          </tr>
        </tbody>
      </table>
      {
        loading && <div className="flex items-center justify-center"><Loading color='dark-grey'/></div>
      }
    </div>
  )
}
