import React, {useState, useContext, useEffect} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';
import Logo from 'assets/images/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import Message from 'components/common/Message';
import RequestFlagsContext from 'contexts/RequestFlagsContext';

const SIGNUP = gql`
  mutation Signup($email: String!, $username: String!, $inviteId: String, $firstName: String!, $lastName: String!, $password: String!) {
    signup(email: $email, firstName: $firstName, lastName: $lastName, password: $password, username: $username, inviteId: $inviteId) {
      id
    }
  }
`;

const SignUp = ({invite, errorOverride, skipErrorHandling}) => {
  const {addLoading, loading, removeLoading} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  let {setErrorHandler} = useContext(RequestFlagsContext);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [signupMutation, { loading: signupLoading }] = useMutation(SIGNUP);

  useEffect(() => {
    if  (!skipErrorHandling) {
      setErrorHandler('Signup', (error) => {
        setError(error);
      });
      return () => setErrorHandler('Signup', undefined);
    }
  }, []);

  if (signupLoading && !loading) {
    addLoading();
  } else if (!signupLoading && loading) {
    removeLoading();
  }
  const valid = () => {
    return firstName && lastName && (step < 1 || email) && (step < 2 || (password && username && (password === confirmPassword)));
  }

  const signUp = (back) => {
    if (back || valid()) {
      if ((step !== 2) || back) {
        setStep(step + (back ? -1 : 1));
      } else {
        signupMutation({ variables: { email, firstName, lastName, password, username, inviteId: invite?.id } })
          .then(res => {
            setStep(3);
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }

  return (
    <div className="top-0 left-0 absolute w-screen h-screen h-screen bg-gradient-to-r from-light-light-yellow dark:from-dark-coffee to-light-yellow dark:to-midnight flex flex-col z-60 justify-center items-center">
      <div className="flex flex-col justify-between p-6 gap-4 w-80 min-h-110 rounded-xl bg-white dark:bg-midnight border dark:border-black">
        <div className="flex flex-col gap-4">
          <Link className="no-underline text-sm self-center" to={'/'}>
            <div className="bg-contain bg-no-repeat grow-0 shirnk-0 w-20 h-12 self-center" style={{backgroundImage: `url(${Logo})`}}/>
          </Link>
          <h2 className="font-bold dark:text-bone">Sign Up</h2>
          {
            step == 0 &&
            <>
              {invite ? 
                <p className="dark:text-bone">
                  You have been invited to join&nbsp;
                  {(invite.firmId) ? `${invite.firm.name} on ` : ''} 
                  ConveyMe by {invite.fromUser.firstName}.
                </p>
                :
                <p className="text-sm dark:text-bone">
                  Already have an account? Login <Link className="no-underline text-primary" to="/login">Here</Link>
                </p>
              }
              <div className="flex flex-col">
                <Label text="First Name"/>
                <Input className="dark:bg-shadow" valueOverride={firstName} onChange={setFirstname}/>
              </div>
              <div className="flex flex-col">
                <Label text="Last Name"/>
                <Input className="dark:bg-shadow" valueOverride={lastName} onChange={setLastname}/>
              </div>
            </>
          }
          {
            step == 1 &&
            <>
              <p className="text-sm dark:text-bone">
                This email will be used to confirm your account.
              </p>
              <div className="flex flex-col">
                <Label text="email"/>
                <Input className="dark:bg-shadow" valueOverride={email} onChange={setEmail}/>
              </div>
            </>
          }
          {
            step == 2 &&
            <>
              {
                (error || errorOverride) &&
                <Message negative>
                  <FontAwesomeIcon icon={faCircleExclamation}/>
                  &nbsp;
                  {(error || errorOverride).message}
                </Message>
              }
              <div className="flex flex-col">
                <Label text="username"/>
                <Input className="dark:bg-shadow" onChange={setUsername}/>
              </div>
              <div className="flex flex-col">
                <Label text="password"/>
                <Input className="dark:bg-shadow" type="password" onChange={setPassword}/>
              </div>
              <div className="flex flex-col">
                <Label text="confirm password"/>
                <Input className="dark:bg-shadow" type="password" onChange={setConfirmPassword}/>
              </div>
            </>
          }
                 {
            step == 3 &&
            <>
              <p className="text-sm dark:text-bone">
                <h3 className="dark:text-bone">Success!</h3>
                Check your email to confirm your account and then login <Link to="/login">Here</Link> 
              </p>
            </>
          }
          {/* {
            <div className="flex flex-col">
              <Label text={<span>Invite Code <small>(optinal)</small></span>}/>
              <Input className="dark:bg-shadow" className="font-bold" onBlur={setInviteId} error={inviteError} readOnly={params.invite} valueOverride={inviteId}/>
            </div>
          } */}
        </div>
        <div className="flex justify-between items-center w-full">
          {((step != 0) && (step != 3)) ?
            <div onClick={() => signUp(true)} className="rounded-full text-transparent-darkest-grey bg-light-light-grey dark:bg-shadow hover:bg-light-grey flex items-center justify-center cursor-pointer w-8 h-8">
              <FontAwesomeIcon icon={faArrowLeft}/>
            </div>
            :
            <div/>
          }
          {/* <FontAwesomeIcon onClick={signUp} className="rounded-full bg-light-light-grey dark:bg-shadow hover:bg-light-grey flex items-center justify-center cursor-pointer p-1.5" icon={faArrowRight}/> */}
          {(step != 3) && 
            <ButtonPrimary className={`self-end`} disabled={loading || !valid()} onClick={() => signUp()}>{step == 2 ? 'Submit' : 'Next'}</ButtonPrimary>
          }
        </div>
      </div>
      <p className="text-center text-xs pt-2">
        To register your firm reach out to us at <br></br><a href="mailto:support@conveyme.ca"><span className="text-xs">support@conveyme.ca</span></a>
      </p>
      <div className="flex p-4 gap-4">
        <Link className="no-underline text-sm" to={'/'}><span className="">About</span></Link>
        <Link className="no-underline text-sm" to={'/terms-and-conditions'}><span className="">Terms</span></Link>
        <Link className="no-underline text-sm" to={'/privacy'}><span className="">Privacy</span></Link>
        {/* <Link className="no-underline text-sm" to="/faqs">Help</Link> */}
      </div>
    </div>
  );
};

export default SignUp;
