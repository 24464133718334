import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import AnonNav from './AnonNav';
import Logo from 'assets/images/logo/logo.png';
import CommonNav from './CommonNav';
import LoadBar from 'components/notices/LoadBar';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSearch from './FileSearch';


const TopNav = ({ childRef }) => {
  const location = useLocation();
  const { currentUser, toggleMenu, menuOpen } = useContext(appContext);
  const [top, setTop] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setTop(scrollTop < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return (() => window.removeEventListener('scroll', handleScroll));
    } else {
      setTop(true);
    }
  }, [location.pathname]);

  return (
    <div className="z-60">
      <div ref={childRef} className="sticky p-2 px-4 pl-3 2xl:py-3 items-center flex z-30">
        <div className="flex items-center gap-3 grow basis-1 mr-auto">
          <div className="hover:bg-transparent-grey flex justify-center items-center p-1 rounded-full h-6 w-6">
            <FontAwesomeIcon className={`cursor-pointer ${menuOpen ? 'animate-spinright' : 'animate-spinleft'} 2xl:text-xl`} icon={faBars} onClick={toggleMenu}/>
          </div>
          <Link className="no-underline" to={`/`}>
            <div className="bg-contain bg-no-repeat w-10 h-6 2xl:w-14 2xl:h-8" style={{backgroundImage: `url(${Logo})`}}/>
          </Link>
          {/* <h3>Home</h3> */}
          {/* {currentUser && <CommonNav/> } */}
        </div>
        <FileSearch className="grow"/>
        {currentUser ?
          <UserNav className="grow justify-end basis-1"/>
          :
          <AnonNav className="grow justify-end basis-1"/>
        }
      </div>
      <LoadBar/>
    </div>
  );
};

export default TopNav;
