import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { file } from 'jszip';
import DocumentGroup from 'components/documents/DocumentGroup';
import Loading from 'components/common/Loading';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $firmId: String, $trustAccountId: String) {
    trustAccountItems(filters: $filters, firmId: $firmId, trustAccountId: $trustAccountId) {
      id,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId, 
      fileId,
      trustAccountId,
      trustAccount {
        name
      }
      type,
      cleared,
      void,
      file {
        fileNumber,
        completionDate,
        surveyName
      }
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month.length > 1 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function TrustAccountList({filters}) {
  const [files, setFiles] = useState({});
  const {setActivePage, from, to, firmId, trustAccountId} = useContext(ActivePageContext);
  setActivePage("list")

  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: ['list']}},
    fetchPolicy:'no-cache'
  });

  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {chequeDepositNumber: false, cleared: false, to: to}},
    fetchPolicy:'no-cache',
    onCompleted: (data) => {
      if (data?.trustAccountItems) {
        let files = {};
        for (let item of data?.trustAccountItems) {
          if (!item.file.fileNumber) continue;
          if (item.void) {
          } else if (item.value < 0){
            if (!files[item.file.fileNumber]) {
              files[item.file.fileNumber] = {
                completionDate: item.file.completionDate, 
                type: item.file.surveyName, 
                balance: 0,
                trustAccount: item.trustAccount,
                surveyId: item.surveyId,
                fileId: item.fileId
              };
            }
            files[item.file.fileNumber].balance += Math.abs(Number(item.value));
          }
        }
        setFiles(files);
      }
    }
  });

  const totals = useMemo(() => {
    return Object.entries(files).reduce((prev, current) => {
      prev.balance += current[1].balance;
      return prev;
    }, {balance: 0});
  }, [files])

  return (
    <div className='flex flex-col gap-4'>
      <div className="bg-light-grey rounded p-2 dark:bg-transparent">
        <table className="w-full bg-light-grey border-spacing-2 dark:bg-transparent">
          <tbody>
            <tr className="border-b border-dark-grey dark:bg-shadow">
              <th>No</th>
              <th>File Matter</th>
              <th>Completion Date</th>
              <th>Type</th>
              <th>Balance</th>
            </tr>
            {Object.entries(files).map(([name, file], i) => (
              <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
                <td className="p-1 text-center">{i+1}</td>
                <td className="p-1 text"><Link to={`/files/${file.surveyId}/${file.fileId}/trust-account`}>{name}</Link></td>
                <td className="p-1 text-center">{file.completionDate}</td>
                <td className="p-1 text-center">{file.type}</td>
                <td className="p-1 text-right">{formatCurrency(file.balance)}</td>
              </tr>
            ))}
            <tr className="border-t border-med-grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right pt-2">{formatCurrency(totals.balance)}</td>
            </tr>
          </tbody>
        </table>
        {
          loading && <div className="flex items-center justify-center"><Loading color='dark-grey'/></div>
        }
      </div>
      {dataDocs && 
        <div className="relative">
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              files,
              totals,
              to: to,
              from: from,
            }}
          />
        </div>
      }
    </div>
  )
}
